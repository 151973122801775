import { useState, useEffect } from "react";
import Search from "./Search";
import Cars from "./Cars";
import { useTranslation } from "react-i18next";
import { Loader } from "../template";
import { useApp } from "../providers/app";
import { Link, useNavigate } from "react-router-dom";
import TableCustom from "../list/TableCustom";
import moment from "moment";
import Countdown from "react-countdown-simple";

import { DATE_FORMAT } from "../defines";
const Home = () => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [list, setlist] = useState([]);
  const navigate = useNavigate();
  const { user } = useApp();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div className="font-medium">{t("end")}</div>;
    } else
      return (
        <div>
          {hours}:{fixer(minutes)}:{fixer(seconds)}
        </div>
      );
  };
  const fixer = (val) => {
    if (val == 0) return "00";
    else if (val.length == 1) return "0" + val;
    else return val;
  };
  return user ? (
    <div className="flex h-full container mx-auto p-4" style={{ height: 1000 }}>
      <TableCustom
        showfilter={true}
        url={`/car/table`}
        defaultFind={{ purchasedby: null }}
        columns={[
          {
            label: t("car.photo"),
            key: "username",
            sortable: false,
            render: ({ urls }) => <img style={{ height: 100 }} src={urls[0]} />,
          },
          {
            label: t("car.info"),
            key: "role",
            sortable: false,
            render: ({ no, time, price, bidder, view, _id }) => {
              console.log("🚀 ~ Home ~ time:", time);
              const date = new Date();
              date.setSeconds(
                date.getSeconds() + time ? time : Math.random() * 10000
              );
              const isoString = date.toISOString();

              return (
                <Link to={`/auction/detail/${_id}`}>
                  <div className="text-center">
                    <div className="font-light underline text-blue-600">
                      {`No. ${no}`}
                    </div>

                    {price == 0 ? (
                      <div className="font-medium mt-1">{t("soon")}</div>
                    ) : (
                      <div>
                        <div className="font-medium mt-1">{t("auction")}</div>
                        <Countdown renderer={renderer} targetDate={isoString} />
                        <div className="font-medium mt-1">{`${t(
                          "until"
                        )}`}</div>
                      </div>
                    )}
                  </div>
                </Link>
              );
            },
          },
          {
            label: t("car.price"),
            key: "role",
            sortable: false,
            render: ({ price }) => {
              const dollar = Math.floor(price * 0.0067);
              return (
                <div className="text-center">
                  {price == 0 ? (
                    <div className="font-medium">{t("soon")}</div>
                  ) : (
                    <div>
                      <div className="font-light">{`${price
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}Yen`}</div>
                      <div className="font-medium">{` (${dollar
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}$ )`}</div>
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            label: t("car.detail"),
            key: "role",
            sortable: false,
            filter: {
              type: "text",
            },
            render: ({ mark, model, year, millage }) => {
              return (
                <div className="text-center">
                  <div className="font-light  text-blue-600">{mark}</div>
                  <div className="font-light  text-blue-600">{model}</div>
                  <div className="font-normal mt-1">{`${year}`}</div>
                  <div className="font-normal mt-1">
                    {`${millage
                      .toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}km`}
                  </div>
                </div>
              );
            },
          },
          {
            label: t("car.detail"),
            key: "role",
            sortable: false,
            filter: {
              type: "text",
            },
            render: ({ damage, area, condition }) => {
              return (
                <div className="text-center">
                  <div className="font-light mt-1">{damage}</div>
                  <div className="font-normal mt-1">{area}</div>
                  <div className="font-light mt-1">{condition}</div>
                </div>
              );
            },
          },
          {
            label: t("car.place"),
            key: "role",
            sortable: false,
            filter: {
              type: "text",
            },
            render: ({ place }) => {
              return (
                <div className="text-center">
                  <div className="font-normal mt-1">{place}</div>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  ) : (
    navigate("/auction/login")
  );
};

export default Home;
