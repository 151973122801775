import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { mainApi } from "../providers/api";

import ImageGallery from "react-image-gallery";
import objectPath from "object-path";
import { useApp } from "../providers/app";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown-simple";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { IoArrowBackOutline } from "react-icons/io5";
import { AiOutlineArrowRight } from "react-icons/ai";
import { errorAlert } from "../providers/alert";
import { Loader } from "../template";

const Detail = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState();
  const [images, setimages] = useState([]);
  const [time, settime] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div className="font-medium">{t("end")}</div>;
    } else
      return (
        <div>
          {hours}:{fixer(minutes)}:{fixer(seconds)}
        </div>
      );
  };
  const fixer = (val) => {
    if (val == 0) return "00";
    else if (val.length == 1) return "0" + val;
    else return val;
  };
  useEffect(() => {
    (async () => {
      setloading(true);
      const res = await mainApi({
        url: `/car/view/${id}`,
        method: "GET",
      });

      setdata(res.data);

      const d = new Date(
        new Date().setHours(new Date().getSeconds() + time)
      )?.toISOString();

      settime(d);
      const photos = objectPath.get(res, "data.photos");

      const urls = objectPath.get(res, "data.urls");

      if (photos.length > 0) {
        const img = [];
        photos.map((url) => {
          img.push({
            original: url,
            thumbnail: url,
          });
        });

        setimages(img);
      } else if (urls) {
        const img = [];
        urls.map((url) => {
          img.push({
            original: url,
            thumbnail: url,
          });
        });

        setimages(img);
      }
      setloading(false);
    })();
  }, []);

  return (
    <div className="flex p-4">
      {loading ? (
        <div className="flex justify-center items-center bg-red-500">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col bg-white p-8">
          <div className="flex flex-col p-4 ml-6">
            <div className="flex">
              <div className="flex items-center" onClick={() => navigate(-1)}>
                <IoArrowBackOutline
                  color="#2379be"
                  className="hover:cursor-pointer mr-4"
                  size={20}
                />
              </div>
              <div className="text-xl">{`No. ${data?.no}`}</div>
              <div className="text-xl ml-4 font-medium">{`${data?.mark} ${data?.model}`}</div>
            </div>
            <div className="flex my-2">
              <div className="border-l-4 border-secondary-100 pl-4 w-64">
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <div className="font-medium">{`${t(
                      "current_price"
                    )} : `}</div>
                    <div className="font-medium text-secondary-100 pl-2">
                      {` ${data?.price
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`}
                    </div>
                    <div className="font-medium pl-1">Yen</div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="font-medium">
                    {`(USD ${Math.floor(data?.price * 0.0067)
                      .toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")})`}
                  </div>
                </div>
              </div>
              <div className="flex flex-col border-l-4 border-secondary-100 pl-4 w-64">
                <div className="font-medium">{`${t("bid")} : ${
                  data?.bidder
                }`}</div>
                <div className="font-medium">{`${t("view")} : ${data?.view}${t(
                  "times"
                )}`}</div>
              </div>
              <div className="border-l-4 border-secondary-100 pl-4 w-64">
                <div className="flex">
                  <div className="font-medium mr-2">{`${t("left")} : `}</div>
                  {data?.price == 0 ? (
                    <div className="font-medium">{t("soon")}</div>
                  ) : data?.purchasedby ? (
                    <div className="font-medium">{t("end")}</div>
                  ) : (
                    <Countdown renderer={renderer} targetDate={time} />
                  )}
                </div>
              </div>
              {data?.purchasedby == null && (
                <div
                  className="flex flex-row w-64 p-5 bg-secondary-100 justify-center items-center hover:cursor-pointer"
                  onClick={() => {
                    errorAlert(t("info.member"));
                  }}
                >
                  <div className="text-white font-medium">
                    {t("info.biding")}
                  </div>
                  <AiOutlineArrowRight
                    className="pl-2"
                    size={30}
                    color="#fff"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap w-screen">
            <div className="flex w-2/5 ">
              {images.length > 0 && (
                <div style={{ maxWidth: 500 }} className="mx-auto">
                  <ImageGallery
                    showFullscreenButton={false}
                    showPlayButton={false}
                    items={images}
                    renderLeftNav={() => {
                      <></>;
                    }}
                    renderRightNav={() => {
                      <></>;
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex w-3/5">
              <Tabs style={{ width: 750 }}>
                <TabList>
                  <Tab>{t("info.info")}</Tab>
                  <Tab>{t("info.damage")}</Tab>
                  <Tab>{t("info.airbag_eq")}</Tab>
                  <Tab>{t("info.other")}</Tab>
                </TabList>

                <TabPanel>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.stock")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.no}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.mark")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.mark}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.model")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.model}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.grade")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.grade}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.chassis")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.chassis}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.color")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.color}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.body")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.body}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.year")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.year}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.mileage")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {`${data?.millage
                          .toString()
                          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}km`}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.engine")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.engine}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.fuel")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.fuel}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.transmission")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.transmission}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.system")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.system}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-28 text-secondary-100 ">
                        {t("info.capacity")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.capacity}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="flex flex-col">
                    <div className="font-normal w-64 text-secondary-100 ">
                      {t("info.situation")}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row justify-start p-2 my-2 bg-gray-100">
                        <div className="font-medium w-48 text-secondary-100 ">
                          {t("info.main_damage")}
                        </div>
                        <div className="font-medium text-left justify-start ml-2">
                          {data?.damage}
                        </div>
                      </div>
                      <div className="flex flex-row justify-start p-2 bg-white">
                        <div className="font-medium w-48 text-secondary-100 ">
                          {t("info.area_damage")}
                        </div>
                        <div className="font-medium text-left justify-start ml-2">
                          {data?.area}
                        </div>
                      </div>
                      <div className="flex flex-row justify-start p-2 bg-gray-100">
                        <div className="font-medium w-48 text-secondary-100 ">
                          {t("info.drive_condition")}
                        </div>
                        <div className="font-medium text-left justify-start ml-2">
                          {data?.condition}
                        </div>
                      </div>
                    </div>
                    <div className="font-normal w-64 text-secondary-100 mt-4">
                      {t("info.engine_room")}
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.engine")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {t("info.start")}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.shift")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {t("info.movable")}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.radiator")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {t("info.ok")}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.oil")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {t("info.ok")}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="flex flex-col">
                    <div className="font-normal w-64 text-secondary-100 ">
                      {t("info.airbag")}
                    </div>
                    <div className="flex flex-row">
                      <div className="flex w-1/2 flex-col">
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.passenger")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-gray-100">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.p_seat")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.p_door")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-gray-100">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.p_curtain")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.p_knee")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-gray-100">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.p_left")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.p_heat")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                      </div>
                      <div className="flex w-1/2 flex-col">
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.driver")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-gray-100">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.d_seat")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.d_door")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-gray-100">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.d_curtain")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.d_knee")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-gray-100">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.d_right")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.bonnet")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="font-normal w-64 text-secondary-100 ">
                      {t("info.equipment")}
                    </div>
                    <div className="flex flex-row">
                      <div className="flex w-1/2 flex-col">
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.ac")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-gray-100">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.pw")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                      </div>
                      <div className="flex w-1/2 flex-col">
                        <div className="flex flex-row justify-start p-2 bg-white">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.ps")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                        <div className="flex flex-row justify-start p-2 bg-gray-100">
                          <div className="font-medium w-48 text-secondary-100 ">
                            {t("info.navigation")}
                          </div>
                          <div className="font-medium text-left justify-start ml-2">
                            {t("info.ok")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.due_place")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {data?.place}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.inspection")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2"></div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.length")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {`${data?.length}cm`}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-gray-100">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.width")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {`${data?.width}cm`}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.height")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {`${data?.height}cm`}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start p-2 bg-white">
                      <div className="font-medium w-48 text-secondary-100 ">
                        {t("info.description")}
                      </div>
                      <div className="font-medium text-left justify-start ml-2">
                        {`${data?.remark}`}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Detail;
