import { useTranslation } from "react-i18next";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { GetFromStorage } from "../providers/storage";
const Offer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user =
    GetFromStorage("user") == undefined
      ? navigate("/auction/login")
      : JSON?.parse(GetFromStorage("user"));
  return (
    <div className="flex flex-col h-full p-8">
      <div className="flex items-center" onClick={() => navigate(-1)}>
        <IoArrowBackOutline
          color="#2379be"
          className="hover:cursor-pointer mr-4"
          size={20}
        />
        <h2 className="border-l-4 pl-4 border-yellow-400 font-bold">
          {t(`my.offer`)}
        </h2>
      </div>
      <div className="flex p-5 bg-white mt-5 h-full justify-center items-center">
        <div>{t("empty.offer")}</div>
      </div>
    </div>
  );
};

export default Offer;
