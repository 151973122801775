import { useTranslation } from "react-i18next";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { useApp } from "../providers/app";
import TableCustom from "../list/TableCustom";
import Countdown from "react-countdown-simple";
import { mainApi } from "../providers/api";
import { Loader } from "../template";
import objectPath from "object-path";
import { GetFromStorage } from "../providers/storage";
import moment from "moment";
import { API_ROOT } from "../defines";
const Purchased = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(false);
  const user =
    GetFromStorage("user") == undefined
      ? navigate("/auction/login")
      : JSON?.parse(GetFromStorage("user"));

  useEffect(() => {
    user && fetch();
  }, []);
  const fetch = async () => {
    try {
      setloading(true);
      const res = await mainApi({
        url: "/car/table",
        method: "POST",
        data: {
          find: {
            purchasedby: user?.id,
          },
          sort: {
            no: -1,
          },
        },
      });

      setlist(res.data.item);
      setloading(false);
    } catch (err) {}
  };

  return (
    <div className="flex flex-col h-full p-8">
      <div className="flex items-center" onClick={() => navigate(-1)}>
        <IoArrowBackOutline
          color="#2379be"
          className="hover:cursor-pointer mr-4"
          size={20}
        />
        <h2 className="border-l-4 pl-4 border-yellow-400 font-bold">
          {t(`my.purchased`)}
        </h2>
      </div>
      <div className="flex p-5 bg-white mt-5 h-screen scroll-auto">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-col justify-start">
            <div className="flex">
              <div className="w-32 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.stock")}
              </div>
              <div className="w-48 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.purchased")}
              </div>
              <div className="w-48 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.invoice")}
              </div>
              <div className="w-32 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.mark")}
              </div>
              <div className="w-32 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.model")}
              </div>
              <div className="w-32 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.amount")}
              </div>
              {/* <div className="w-32 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.fob")}
              </div> */}
              {/* <div className="w-32 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.total")}
              </div> */}
              {/* <div className="w-32 bg-secondary-100 text-white border-r-2 border-white text-left p-2">
                {t("info.paid")}
              </div> */}
            </div>
            {list?.map((row, index) => {
              const mark = objectPath.get(row, "mark");
              const model = objectPath.get(row, "model");
              const price = objectPath.get(row, "price");
              const no = objectPath.get(row, "no");
              const invoice = objectPath.get(row, "invoice");
              const id = objectPath.get(row, "id");
              const purchaseDate = objectPath.get(row, "purchaseDate");

              return (
                <div
                  className={`flex  ${
                    index % 2 == 0 ? "bg-gray-100" : "bg-white"
                  }`}
                >
                  <Link to={`/auction/detail/${id}`}>
                    <div className="w-32 text-secondary-100 underline p-2 ">
                      {no}
                    </div>
                  </Link>
                  <div className="w-48 p-2">{purchaseDate}</div>
                  <div className="w-48 p-2">
                    <div>{invoice}</div>
                  </div>
                  <div className="w-32 p-2 ">{mark}</div>
                  <div className="w-32 p-2">{model}</div>
                  <div className="w-32 p-2">{`${price
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Yen`}</div>
                  {/* <div className="w-32 p-2">{`${fob
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Yen`}</div>
                  <div className="w-32 p-2">{`${total
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Yen`}</div>
                  <div className="w-32 p-2">{`${total
=======
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Yen`}</div>          
                  <div className="w-32 p-2">{`${price
>>>>>>> refs/remotes/origin/main
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Yen`}</div> */}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Purchased;
