import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import "./providers/i18n";

import AppProvider from "./providers/app";
import Loader from "./pages/Loader";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Privacy from "./pages/Privacy";

import PublicLayout from "./layouts/Public";
import AuthLayout from "./layouts/Auth";
import axios from "axios";
import { API_ROOT } from "./defines";
import Mypage from "./pages/MyPage";
import Favorite from "./pages/Favorite";
import Offer from "./pages/OfferHistory";
import Wishlist from "./pages/WishList";
import Purchased from "./pages/Purchased";
import FAQ from "./pages/FAQ";
import Notification from "./pages/Notification";
import Special from "./pages/Special";
import MyAuction from "./pages/MyAuction";
import Detail from "./pages/Detail";
import Registration from "./pages/Registration";
import "react-tabs/style/react-tabs.css";

axios.defaults.baseURL = API_ROOT;
document.addEventListener("wheel", function () {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <AppProvider>
          <div className="h-full w-full flex">
            <Routes>
              <Route path="/" element={<Navigate to="/auction/login" />} />
              <Route path="auction" element={<PublicLayout />}>
                <Route path="home" element={<Home />} />
                <Route path="mypage" element={<Mypage />} />
                <Route path="login" element={<Login />} />
                <Route path="policy" element={<Privacy />} />
                <Route path="favorite" element={<Favorite />} />
                <Route path="offer" element={<Offer />} />
                <Route path="wishlist" element={<Wishlist />} />
                <Route path="purchased" element={<Purchased />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="notification" element={<Notification />} />
                <Route path="special" element={<Special />} />
                <Route path="registration" element={<Registration />} />
                <Route path="my" element={<MyAuction />} />
                <Route path="detail/:id" element={<Detail />} />
              </Route>
              <Route path="auth" element={<AuthLayout />}>
                <Route path="login" element={<Login />} />

                <Route path="logout" element={<Login from="inside" />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </AppProvider>
      </Suspense>
    </React.StrictMode>
  </BrowserRouter>
);
