import { Outlet, useLocation } from "react-router-dom";
import { FaQ } from "react-icons/fa6";
import { BiBookBookmark } from "react-icons/bi";
import { RiLoginBoxLine, RiHome3Line, RiUser3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../language/Switch";
import { useApp } from "../providers/app";
import { useEffect } from "react";
const PublicMenu = () => {
  const { t } = useTranslation();
  const { user } = useApp();
  return (
    <div className="sticky top-0 bg-white h-20 z-20 flex items-center shadow-md p-4">
      <div className="container mx-auto px-4 flex items-center justify-between">
        <div className="lg:flex hidden items-center">
          <img src="/logo.svg" alt="logo" className="h-10" />
          <div
            className="uppercase text-secondary-100 ml-1 leading-4"
            style={{ width: 500 }}
          ></div>
        </div>
        <div className="flex item-center">
          <Link
            to={"home"}
            className="flex flex-col justify-center align-middle items-center hover:cursor-pointer mx-4"
          >
            <RiHome3Line size={20} color="rgba(35, 121, 190, 1)" />
            <div className=" text-xs font-normal mt-1">
              {t("button.home").toUpperCase()}
            </div>
          </Link>
          <Link
            to={"policy"}
            className="flex flex-col justify-center align-middle items-center hover:cursor-pointer mx-4"
          >
            <BiBookBookmark size={20} color="rgba(35, 121, 190, 1)" />
            <div className="text-xs font-normal mt-1">
              {t("button.policy").toUpperCase()}
            </div>
          </Link>
          {/* <Link
            to={"faq"}
            className="flex flex-col justify-center align-middle items-center hover:cursor-pointer mx-4"
            onClick={() => {}}
          >
            <FaQ size={20} color="rgba(35, 121, 190, 1)" />
            <div className=" text-xs font-normal mt-1">
              {t("button.faq").toUpperCase()}
            </div>
          </Link> */}
          {user && (
            <Link
              to={"mypage"}
              className="flex flex-col justify-center align-middle items-center hover:cursor-pointer mx-4"
            >
              <RiUser3Fill size={20} color="rgba(35, 121, 190, 1)" />
              <div className=" text-xs font-normal mt-1">
                {t("button.mypage").toUpperCase()}
              </div>
            </Link>
          )}
          {user ? (
            <Link
              to={"login"}
              className="flex flex-col justify-center align-middle items-center hover:cursor-pointer mx-4"
            >
              <RiLoginBoxLine size={20} color="rgba(35, 121, 190, 1)" />
              <div className=" text-xs font-normal mt-1">
                {t("button.logout").toUpperCase()}
              </div>
            </Link>
          ) : (
            <Link
              to={"login"}
              className="flex flex-col justify-center align-middle items-center hover:cursor-pointer mx-4"
            >
              <RiLoginBoxLine size={20} color="rgba(35, 121, 190, 1)" />
              <div className=" text-xs font-normal mt-1">
                {t("button.login").toUpperCase()}
              </div>
            </Link>
          )}
          <LanguageSwitch />
        </div>
      </div>
    </div>
  );
};

const PublicFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="border-t">
      <div className="bg-secondary-100 text-center p-2 text-white text-sm">
        <div className="text-sm">{`Copyright © 2021 ${t(
          "company"
        )}. All Rights Reserved.`}</div>
      </div>
    </div>
  );
};

const PublicLayout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="w-full h-screen flex flex-col justify-between">
      <PublicMenu />
      <Outlet />
      <PublicFooter />
    </div>
  );
};

export default PublicLayout;
