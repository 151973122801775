import { useContext, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { RiCloseFill } from "react-icons/ri";
import { GetFromStorage, RemoveFromStorage, SaveToStorage } from "./storage";
import { mainApi } from "./api";
import { locales } from "../language/Switch";
import axios from "axios";

window.addEventListener("storage", (event) => {
  if (event.key === "logged-out") {
    window.location = "/auth/logout";
    window.close();
  }
});

const AppContext = createContext({
  user: null,
  badge: 0,
  login: () => {},
  logout: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
});

const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState();
  const [drawer, setDrawer] = useState();
  const [drawerWidth, setDrawerWidth] = useState(0);

  const login = async (instance) => {
    setInstance(instance.user, instance.token);
    navigate("/auction/mypage");
  };

  const logout = () => {
    setInstance(null, null);
  };

  const setInstance = (user, token) => {
    if (user) {
      SaveToStorage("user", JSON.stringify(user));
      SaveToStorage("token", token);
      setUser(user);
    } else {
      RemoveFromStorage("user");
      RemoveFromStorage("token");
      setUser(null);
    }
  };

  const openDrawer = (content, width = 250) => {
    setDrawer(content);
    setDrawerWidth(width);
  };

  const closeDrawer = () => {
    setDrawer(null);
    setDrawerWidth(0);
  };

  useEffect(() => {
    (async () => {
      const user = GetFromStorage("user");
      const token = GetFromStorage("token");
      token && setInstance(JSON.parse(user), token);
    })();
  }, []);

  useEffect(() => {
    document.title = t("header");
    if (!locales.includes(i18n.language)) {
      i18n.changeLanguage(locales[0]);
    }
  }, [i18n.language]);

  return (
    <AppContext.Provider
      value={{ user, login, logout, openDrawer, closeDrawer }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
export default AppProvider;
