import { useTranslation } from "react-i18next";
import { useApp } from "../providers/app";
import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Field from "../components/Field";
import { useEffect, useState } from "react";

import axios from "axios";
import sha256 from "crypto-js/sha256";
import Loader from "../template/Loader";
import { Link } from "react-router-dom";

const Login = () => {
  const { t } = useTranslation();
  const { login, logout } = useApp();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    logout();
  }, [logout]);

  const onSubmit = async ({ email, password }) => {
    try {
      setloading(true);
      const response = await axios({
        url: "/user/login",
        method: "POST",
        data: {
          email: email,
          password: sha256(password).toString(),
        },
      });

      return login(response.data);
    } catch (error) {
      setloading(false);
      return Swal.fire(
        `警告`,
        error.response?.data || error.toString(),
        "error"
      );
    }
  };

  const Validation = Yup.object().shape({
    email: Yup.string().required(t("error.email")),
    password: Yup.string().required(t("error.password")),
  });

  return (
    <div className="text-center justify-center w-96 mx-auto">
      <img src="/logo.svg" className="mb-20" />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Validation}
        onSubmit={onSubmit}
      >
        {({}) => (
          <Form className="flex flex-col gap-5 my-10 mt-5">
            <Field
              autoFocus={true}
              name="email"
              placeholder={t("button.email")}
              className="uppercase"
            />
            <Field
              name="password"
              autoFocus={true}
              placeholder={t("button.password")}
              type="password"
            />

            <button type="submit">
              {loading ? <Loader /> : t("button.login")}
            </button>
            <Link
              style={{ backgroundColor: "#fbbf24" }}
              className="text-white p-3 rounded"
              to="/auction/registration"
            >
              {t("button.registration")}
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
