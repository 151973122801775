import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Field from "../components/Field";
import Swal from "sweetalert2";
import Loader from "../template/Loader";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const Registration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const Validation = Yup.object().shape({
    name: Yup.string().required(t("error.required")),
    id: Yup.string().required(t("error.required")),
    business: Yup.string().required(t("error.required")),
    street: Yup.string().required(t("error.required")),
    city: Yup.string().required(t("error.required")),
    zipcode: Yup.string().required(t("error.required")),
    tel: Yup.string().required(t("error.required")),
    tel2: Yup.string().required(t("error.required")),
    email: Yup.string().required(t("error.required")),
    re_email: Yup.string().required(t("error.required")),
    password: Yup.string().required(t("error.required")),
    agree: Yup.string().required(t("error.required")),
  });
  const onSubmit = async ({ email, password }) => {
    setloading(true);
    setTimeout(() => {
      Swal.fire("", t("register.registered"), "success").then(() => {
        navigate("/auction/login");
      });
    }, 1000);
  };
  return (
    <div className="flex">
      <div className="flex flex-col w-2/3 mx-auto bg-white p-8 rounded-md shadow-md">
        <div className="font-medium text-xl border-b-2 pb-2 border-secondary-100">
          {t("button.registration")}
        </div>
        <Formik
          initialValues={{
            name: "",
            id: "",
            business: "",
            street: "",
            city: "",
            zipcode: "",
            tel: "",
            tel2: "",
            email: "",
            re_email: "",
            password: "",
          }}
          validationSchema={Validation}
          onSubmit={onSubmit}
        >
          {({}) => (
            <Form className="flex flex-col gap-5 my-10 mt-5">
              <div className="font-medium text-xl">{t("register.company")}</div>
              <Field
                autoFocus={true}
                name="name"
                placeholder={t("register.company_name")}
              />
              <Field
                autoFocus={true}
                name="id"
                placeholder={t("register.company_id")}
              />
              <Field
                autoFocus={true}
                name="business"
                placeholder={t("register.business")}
              />
              <div className="font-medium text-xl">{t("register.address")}</div>
              <Field
                autoFocus={true}
                name="street"
                placeholder={t("register.street")}
              />
              <Field
                autoFocus={true}
                name="city"
                placeholder={t("register.city")}
              />
              <Field
                autoFocus={true}
                name="zipcode"
                placeholder={t("register.zip")}
              />
              <Field
                autoFocus={true}
                name="tel"
                placeholder={t("register.tel")}
              />

              <Field
                autoFocus={true}
                name="tel2"
                placeholder={t("register.tel_2")}
              />
              <Field
                autoFocus={true}
                name="email"
                placeholder={t("register.email")}
              />
              <Field
                autoFocus={true}
                name="re_email"
                placeholder={t("register.re_email")}
              />

              <Field
                autoFocus={true}
                name="password"
                placeholder={t("register.password")}
              />
              <div className="flex flex-row">
                <div className="font-normal text-base">
                  {t("register.policy")}
                </div>

                <div className="flex flex-row ml-4">
                  <Field
                    autoFocus={true}
                    name="agree"
                    type="checkbox"
                    placeholder={t("register.agree")}
                  />
                  <div>{t("register.agree")}</div>
                </div>
              </div>
              <div className="flex justify-end">
                <button type="submit">
                  {loading ? <Loader /> : t("button.registration")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Registration;
