import { useTranslation } from "react-i18next";
import objectPath from "object-path";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import { GetFromStorage } from "../providers/storage";

const Mypage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user =
    GetFromStorage("user") == undefined
      ? navigate("/auction/login")
      : JSON?.parse(GetFromStorage("user"));
  const menu = [
    { title: t(`my.auction`), page: "my" },
    { title: t(`my.wishlist`), page: "wishlist" },
    { title: t(`my.favorite`), page: "favorite" },
    { title: t(`my.special`), page: "special" },
    { title: t(`my.purchased`), page: "purchased" },
    { title: t(`my.offer`), page: "offer" },
  ];
  return (
    <div className="flex flex-col h-full p-8">
      <h2 className="bordered font-bold">{t(`button.mypage`)}</h2>
      <div className="flex p-5 bg-white mt-5">
        <div className="flex flex-col w-full">
          <div className="p-4 font-medium">{`${t("mr")}. ${
            user?.firstname
          }`}</div>
          {menu.map((row, index) => {
            const page = objectPath.get(row, "page");
            const title = objectPath.get(row, "title");
            return (
              <Link
                className={`flex w-full p-4 underline font-medium text-base text-secondary-100 align-middle items-center ${
                  index % 2 == 0 ? `bg-gray-200` : ``
                }`}
                to={`/auction/${page}`}
                key={index}
              >
                <BsArrowRightShort size={25} />
                <div className="ml-4">{`${title}`}</div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Mypage;
