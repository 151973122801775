import { useTranslation } from "react-i18next";
import Collapsible from "react-collapsible";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto py-5">
      <div className="mt-4">
        <Collapsible
          classParentString="bg-secondary-100 p-2 text-white"
          trigger={`1. ${t("faq.topic1.title")}`}
        >
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic1.q1")}`}
          >
            <p className="font-semibold">{`A.${t("faq.topic1.a1")}`}</p>
            <div className="pl-4">
              <p>{`${t("faq.topic1.a11")}`}</p>
              <div className="ml-4">{`${t("faq.topic1.a111")}`}</div>
              <div className="ml-4">{`${t("faq.topic1.a112")}`}</div>
              <p>{`${t("faq.topic1.a12")}`}</p>
              <div className="ml-4">{`${t("faq.topic1.a121")}`}</div>
            </div>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic1.q2")}`}
          >
            <p className="font-semibold">{`A. ${t("faq.topic1.a2")}`}</p>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic1.q3")}`}
          >
            <p className="font-semibold">{`A. ${t("faq.topic1.a3")}`}</p>
          </Collapsible>
        </Collapsible>
      </div>
      <div className="mt-4">
        <Collapsible
          classParentString="bg-secondary-100 p-2 text-white"
          trigger={`2. ${t("faq.topic2.title")}`}
        >
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic2.q1")}`}
          >
            <div>
              <div className="font-semibold">{`A. ${t("faq.topic2.a1")}`}</div>
              <div>
                {`1) ${t("faq.topic2.a11")}
                <a className="font-bold" href="">
                  ${t("this")}
                </a>`}
              </div>
              <div>{`2) ${t("faq.topic2.a12")}`}</div>
            </div>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic2.q2")}`}
          >
            <a className="font-bold" href="">
              {`A. ${t("faq.topic2.a2")}`}
            </a>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic2.q3")}`}
          >
            <a className="font-bold" href="">
              {`A. ${t("faq.topic2.a3")}`}
            </a>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic2.q4")}`}
          >
            <a className="font-bold" href="">
              {`A. ${t("faq.topic2.a3")}`}
            </a>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic2.q5")}`}
          >
            <div>{`A. ${t("faq.topic2.a5")}`}</div>
          </Collapsible>
        </Collapsible>
      </div>
      <div className="mt-4">
        <Collapsible
          classParentString="bg-secondary-100 p-2 text-white"
          trigger={`3. ${t("faq.topic3.title")}`}
        >
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic3.q1")}`}
          >
            <div>{`A. ${t("faq.topic3.a1")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic3.q2")}`}
          >
            <div>{`A. ${t("faq.topic3.a2")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic3.q3")}`}
          >
            <div>{`A. ${t("faq.topic3.a3")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic3.q4")}`}
          >
            <div>{`A. ${t("faq.topic3.a4")}`}</div>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic3.q5")}`}
          >
            <div>{`A. ${t("faq.topic3.a5")}`}</div>
          </Collapsible>
        </Collapsible>
      </div>
      <div className="mt-4">
        <Collapsible
          classParentString="bg-secondary-100 p-2 text-white"
          trigger={`4. ${t("faq.topic4.title")}`}
        >
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic4.q1")}`}
          >
            <div>{`A. ${t("faq.topic4.a1")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic4.q2")}`}
          >
            <div>{`A. ${t("faq.topic4.a2")}`}</div>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic4.q3")}`}
          >
            <div>{`A. ${t("faq.topic4.a3")}`}</div>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic4.q4")}`}
          >
            <div>{`A. ${t("faq.topic4.a4")}`}</div>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic4.q5")}`}
          >
            <div>{`A. ${t("faq.topic4.a5")}`}</div>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic4.q6")}`}
          >
            <div>{`A. ${t("faq.topic4.a6")}`}</div>
          </Collapsible>
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic4.q7")}`}
          >
            <div>{`A. ${t("faq.topic4.a7")}`}</div>
          </Collapsible>
        </Collapsible>
      </div>
      <div className="mt-4">
        <Collapsible
          classParentString="bg-secondary-100 p-2 text-white"
          trigger={`5. ${t("faq.topic5.title")}`}
        >
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic5.q1")}`}
          >
            <div>{`A. ${t("faq.topic5.a1")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic5.q2")}`}
          >
            <div>{`A. ${t("faq.topic5.a2")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic5.q3")}`}
          >
            <div>{`A. ${t("faq.topic5.a3")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic5.q4")}`}
          >
            <div>{`A. ${t("faq.topic5.a4")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic5.q5")}`}
          >
            <div>{`A. ${t("faq.topic5.a5")}`}</div>
          </Collapsible>
        </Collapsible>
      </div>
      <div className="mt-4">
        <Collapsible
          classParentString="bg-secondary-100 p-2 text-white"
          trigger={`6. ${t("faq.topic6.title")}`}
        >
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic6.q1")}`}
          >
            <div>{`A. ${t("faq.topic6.a1")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic6.q2")}`}
          >
            <div>{`A. ${t("faq.topic6.a2")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic6.q3")}`}
          >
            <div>{`A. ${t("faq.topic6.a3")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic6.q4")}`}
          >
            <div>{`A. ${t("faq.topic6.a4")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic6.q5")}`}
          >
            <div>{`A. ${t("faq.topic6.a5")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic6.q6")}`}
          >
            <div>{`A. ${t("faq.topic6.a6")}`}</div>
          </Collapsible>
        </Collapsible>
      </div>
      <div className="mt-4">
        <Collapsible
          classParentString="bg-secondary-100 p-2 text-white"
          trigger={`7. ${t("faq.topic7.title")}`}
        >
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic7.q1")}`}
          >
            <div>{`A. ${t("faq.topic7.a1")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic7.q2")}`}
          >
            <div>{`A. ${t("faq.topic7.a2")}`}</div>
          </Collapsible>

          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic7.q3")}`}
          >
            <div>{`A. ${t("faq.topic7.a3")}`}</div>
          </Collapsible>
        </Collapsible>
      </div>
      <div className="mt-4">
        <Collapsible
          classParentString="bg-secondary-100 p-2 text-white"
          trigger={`8. ${t("faq.topic8.title")}`}
        >
          <Collapsible
            classParentString="bg-secondary-100 p-2 text-white"
            trigger={`Q. ${t("faq.topic8.q1")}`}
          >
            <div>{`A. ${t("faq.topic8.a1")}`}</div>
          </Collapsible>
        </Collapsible>
      </div>
    </div>
  );
};

export default Privacy;
